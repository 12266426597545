<template>
    <div class="container">
        <h1>{{$t('landing.heading')}}</h1>
        <p>{{$t('landing.subtitle')}}</p>
    </div>
</template>

<script>
export default {
    name: 'Index'
}
</script>

<i18n>
{
    "cz": {
        "landing": {
            "heading": "Vítejte v IZCentral portálu",
            "subtitle": "Pokračujte výběrem požadované agendy z hlavního menu. V případě dotazů nebo nesrovnalostí se můžete obrátit na email: iz@cyrrus.cz."
        }
    },
    "en": {
        "landing": {
            "heading": "Welcome to the IZCentral",
            "subtitle": "Continue by selecting the required agenda from the main menu. In case of questions or discrepancies, you can contact us by the email on: iz@cyrrus.cz."
        }
    }
}
</i18n>
